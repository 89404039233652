/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:18ee1a49-473c-4428-8593-a338cc84ad9c",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_MTvLPJekc",
    "aws_user_pools_web_client_id": "6rsun902fhiekj634bar1fmi93",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://oggo8bb9yg.execute-api.ap-southeast-2.amazonaws.com/develop",
            "region": "ap-southeast-2"
        },
        {
            "name": "tfleetApi",
            "endpoint": "https://4d7w10kard.execute-api.ap-southeast-2.amazonaws.com/develop",
            "region": "ap-southeast-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "tfleetVehicles-develop",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tfleetDrivers-develop",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tfleetActions-develop",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "tfleet-documents91159-develop",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
